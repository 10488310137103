import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e6914a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "card-title m-0" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "svg-icon svg-icon-2" }
const _hoisted_8 = { class: "card-body p-9" }
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = { class: "w-50px h-50px d-flex align-items-center justify-content-center p-2 bg-light-primary rounded-3 me-2" }
const _hoisted_11 = { class: "flex-fill d-flex-column justify-content-center" }
const _hoisted_12 = { class: "text-nowrap" }
const _hoisted_13 = { class: "text-nowrap" }
const _hoisted_14 = { class: "mb-1" }
const _hoisted_15 = { class: "fs-7 text-gray-400" }
const _hoisted_16 = { class: "mb-1" }
const _hoisted_17 = { class: "fs-7 text-gray-400" }
const _hoisted_18 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_productTable = _resolveComponent("productTable")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatablePlus = _resolveComponent("MBDatatablePlus")!
  const _component_addShipments = _resolveComponent("addShipments")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.t("fulfillmentOrder.shipment")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives((_openBlock(), _createElementBlock("button", {
          type: "button",
          class: "btn btn-primary",
          "data-bs-toggle": "modal",
          "data-bs-target": "#modal_add_fulfillment_shipments",
          disabled: _ctx.isDisabledAdd
        }, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr075.svg" })
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.t("common.add")) + _toDisplayString(_ctx.t("common.shipments")), 1)
        ], 8, _hoisted_6)), [
          [_directive_auth, { auth: ['update'] }]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_MBDatatablePlus, {
        loading: _ctx.loading,
        "table-data": _ctx.tableData,
        "table-header": _ctx.tableHeader,
        "enable-items-per-page-dropdown": true,
        "current-page": _ctx.currentPage,
        total: _ctx.total,
        "rows-per-page": _ctx.pageSize,
        "expand-all": true,
        "show-expand-btn": true,
        sameTable: false,
        onCurrentChange: _ctx.onCurrentPageChange,
        onItemsPerPageChange: _ctx.onRowsPerPageChange,
        onSort: _ctx.onColumnSort
      }, {
        "cell-sub": _withCtx(({ row: item }) => [
          (_openBlock(), _createBlock(_component_productTable, {
            key: item.id,
            id: item.id,
            fulfillment_order_number: 
              String(_ctx.$route.params.fulfillment_order_number)
            
          }, null, 8, ["id", "fulfillment_order_number"]))
        ]),
        "cell-tracking_no": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              (_ctx.getServiceProvider(item.service_provider))
                ? (_openBlock(), _createBlock(_component_el_image, {
                    key: 0,
                    class: "d-flex",
                    lazy: "",
                    fit: "contain",
                    src: _ctx.getServiceProvider(item.service_provider)
                  }, null, 8, ["src"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_router_link, {
                to: 
                  '/transport-management/shipments/' +
                  item.id +
                  '/' +
                  item.shipment_id +
                  '/edit'
                ,
                class: "text-gray-800 text-hover-primary d-block leading-none mb-1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.tracking_no), 1)
                ]),
                _: 2
              }, 1032, ["to"]),
              _createVNode(_component_router_link, {
                to: 
                  '/transport-management/shipments/' +
                  item.id +
                  '/' +
                  item.shipment_id +
                  '/edit'
                ,
                class: "fs-7 text-gray-400 text-hover-primary d-block leading-none"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.shipment_id), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ])
          ])
        ]),
        "cell-actual_time_of_departure": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.formatDate(item.actual_time_of_departure)), 1)
        ]),
        "cell-actual_time_of_arrival": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.formatDate(item.actual_time_of_arrival)), 1)
        ]),
        "cell-delivery_from": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_14, _toDisplayString(item.__show.delivery_from_country), 1),
          _createElementVNode("div", _hoisted_15, _toDisplayString(item.__show.delivery_from), 1)
        ]),
        "cell-destination": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_16, _toDisplayString(item.__show.destination_country), 1),
          _createElementVNode("div", _hoisted_17, _toDisplayString(item.__show.destination), 1)
        ]),
        "cell-status": _withCtx(({ row: item }) => [
          _createElementVNode("span", {
            class: _normalizeClass(["badge", _ctx.getShipmentStatusMap(Number(item.status))])
          }, _toDisplayString(item.__show.status), 3)
        ]),
        "cell-actions": _withCtx(({ row: item }) => [
          _createVNode(_component_router_link, {
            to: 
              '/transport-management/shipments/' +
              item.id +
              '/' +
              item.shipment_id +
              '/edit'
            ,
            class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_18, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
    ]),
    _createVNode(_component_addShipments, {
      fulfillment_order_id: Number(_ctx.route.params.id),
      fulfillment_order_number: String(_ctx.route.params.fulfillment_order_number),
      fulfillment_order_type: _ctx.options.fulfillment_order_type,
      custom_declaration_by: _ctx.options.fulfillment_type,
      onUpdateList: _ctx.renew
    }, null, 8, ["fulfillment_order_id", "fulfillment_order_number", "fulfillment_order_type", "custom_declaration_by", "onUpdateList"])
  ]))
}