import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "elplus_dataTables_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_info = _resolveComponent("product-info")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_sku_info = _resolveComponent("sku-info")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      class: "w-100",
      data: _ctx.formData.product_items,
      size: "small"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: _ctx.t('order.product'),
          width: "500"
        }, {
          default: _withCtx(({ row }) => [
            _createVNode(_component_product_info, {
              gallery: _ctx.getProductIdData(row.sku_identifier)?.gallery,
              name: _ctx.getProductIdData(row.sku_identifier)?.name,
              name_en: _ctx.getProductIdData(row.sku_identifier)?.name_en,
              source_link: _ctx.getProductIdData(row.sku_identifier)?.source_link,
              brand_id: _ctx.getProductIdData(row.sku_identifier)?.__show.brand_id
            }, null, 8, ["gallery", "name", "name_en", "source_link", "brand_id"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.t('order.sku')
        }, {
          default: _withCtx(({ row }) => [
            _createVNode(_component_sku_info, {
              brand_article_no: 
              _ctx.getProductIdData(row.sku_identifier)?.brand_article_no
            ,
              color: _ctx.getProductIdData(row.sku_identifier)?.original_color,
              size: _ctx.getProductIdData(row.sku_identifier)?.__show.size,
              product_identifier_id: 
              _ctx.getProductIdData(row.sku_identifier)?.product_identifier_id
            
            }, null, 8, ["brand_article_no", "color", "size", "product_identifier_id"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.t('order.quantity'),
          prop: "quantity",
          align: "right"
        }, null, 8, ["label"])
      ]),
      _: 1
    }, 8, ["data"])
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}