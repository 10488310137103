
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import {
  ApiBase,
  ApiFulfillmentOrders,
  ApiShipments,
  ApiStock,
} from "@/core/api";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import FilterDropdown from "./AddStockOutFilterDropdown.vue";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import FsLightbox from "fslightbox-vue/v3";
import { TaggingItem } from "@/core/directive/interface/common";
import {
  ProductItem,
  ProductItemImage,
  ProductItemOfficialPrices,
} from "@/core/directive/interface/order";
import {
  getOrderOfficialPrices,
  getOrderProductIdData,
  previewOrderImages,
} from "@/core/directive/function/order";
import {
  CommonFormType,
  CommonHtmlType,
  NumberOrString,
} from "@/core/directive/type/common";
import {
  AddStockOutListFilterOption,
  AddStockOutListSubmitData,
} from "@/core/directive/interface/shipment";
import { formatDateTime, showModal } from "@/core/directive/function/common";
import { ElTable } from "element-plus";
import { FulfillmentSource } from "@/core/directive/type/fulfillmentOrder";

export default defineComponent({
  name: "add-pre-stock-out-list",
  components: {
    FilterDropdown,
    FsLightbox,
  },
  props: {
    fulfillment_order_id: {
      type: Number,
      required: false,
      default: 0,
    },
    fulfillment_order_number: {
      type: String,
      required: false,
      default: "",
    },
    fulfillment_order_type: {
      type: Number,
      required: false,
      default: 0,
    },
    id: { type: Number, required: false, default: 0 },
    custom_declaration_by: { type: String, required: false, default: "" },
    show: { type: undefined || Boolean, default: undefined },
    submitData: {
      type: Object as () => AddStockOutListSubmitData,
      required: true,
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<CommonFormType>(null);
    const addPreStockOutList = ref<CommonHtmlType>(null);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const submitButton = ref<CommonHtmlType>(null);
    const isHide = ref<boolean>(false);
    const testM = ref(props.id);

    const filterOptions = ref<AddStockOutListFilterOption>({
      sale_order_number: "",
      destination_warehouse_id: "",
      customer: "",
      priority: "",
      order_date_start: null,
      order_date_end: null,
    });

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      custom_declaration_by: "1",
      stock_out_ids: [] as number[],
      stock_out_list: [] as any[],
    });

    const rules = ref({});

    const options = ref({
      custom_declaration_by: [] as TaggingItem[],
      list: [] as any[],
      stock_out_list: [] as any[],
      cloneTableData: [] as any[],
      product_list: [] as ProductItem[],
      loadingProducts: true,
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
      customer: [] as TaggingItem[],
      isReset: false,
    });

    watch(
      () => props.show,
      (newValue) => {
        if (newValue) {
          showModal(addPreStockOutList.value);
        } else if (!isHide.value) {
          hideModal(addPreStockOutList.value);
        }
      }
    );

    watch(
      () => props.custom_declaration_by,
      (newValue) => {
        formData.value.custom_declaration_by = newValue;
      }
    );

    watch(
      () => formData.value.custom_declaration_by,
      (newValue) => {
        if (props.custom_declaration_by != "") {
          formData.value.custom_declaration_by = props.custom_declaration_by;
        }
      }
    );

    watch(
      () => options.value.list,
      (newValue) => {
        setTableSelect();
      }
    );

    const getFilterList = () => {
      let resultList: any = options.value.stock_out_list;
      if (props.fulfillment_order_type == FulfillmentSource.Merchant) {
        resultList = resultList.filter((item) => {
          return item.quantity - item.delivery_quantity > 0;
        });
      }
      if (
        props.submitData.shipment_type === "2" &&
        props.fulfillment_order_type != FulfillmentSource.Merchant
      ) {
        resultList = resultList.filter((item) => {
          return (
            item.custom_declaration_by == formData.value.custom_declaration_by
          );
        });
      }
      return resultList;
    };

    const handleSelectionChange = (selection) => {
      if (selection.length === 0 && !loading.value) {
        options.value.list.map((row) => {
          if (formData.value.stock_out_ids.indexOf(row.id) > -1) {
            formData.value.stock_out_ids.splice(
              formData.value.stock_out_ids.indexOf(row.id),
              1
            );
            formData.value.stock_out_list.splice(
              formData.value.stock_out_ids.indexOf(row.id),
              1
            );
          }
        });
      } else {
        selection.map((item) => {
          if (formData.value.stock_out_ids.indexOf(item.id) === -1) {
            formData.value.stock_out_ids.push(item.id);
            formData.value.stock_out_list.push(item);
          }
        });
      }
      setTableSelect();
    };

    const handleSelectChange = (selection, row) => {
      if (formData.value.stock_out_ids.indexOf(row.id) > -1) {
        formData.value.stock_out_ids.splice(
          formData.value.stock_out_ids.indexOf(row.id),
          1
        );
        formData.value.stock_out_list.splice(
          formData.value.stock_out_ids.indexOf(row.id),
          1
        );
      }
    };

    const getProductsData = async (ids) => {
      options.value.loadingProducts = true;
      const { data } = await ApiBase.getProductsData(ids);
      options.value.loadingProducts = false;
      if (data.code == 0) {
        options.value.product_list = data.data;
      }
    };

    const getProductIdData = computed(() => {
      return (id: NumberOrString) => {
        return getOrderProductIdData(id, options.value.product_list);
      };
    });

    const getOfficialPrices = computed(() => {
      return (officialPrices: ProductItemOfficialPrices[] | undefined) => {
        return getOrderOfficialPrices(officialPrices);
      };
    });

    const previewImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      options.value.sources = previewOrderImages(previewImages);
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    const handleFilter = (val) => {
      filterOptions.value = val;
      getFormInfo();
    };

    const handleReset = () => {
      formData.value.stock_out_ids = [];
      formData.value.stock_out_list = [];
      options.value.stock_out_list = [];
      options.value.list = [];
      options.value.cloneTableData = [];
    };

    const setTableSelect = () => {
      options.value.list.forEach((item: any) => {
        if (formData.value.stock_out_ids.indexOf(item.id) > -1) {
          multipleTableRef.value?.toggleRowSelection(item, true);
        }
      });
    };

    const isDisabledSubmit = computed(() => {
      const stock_out_ids: any = formData.value.stock_out_ids,
        items: any = getFulfillmentOrderItems.value;
      if (stock_out_ids.length > 0) {
        // 商户采购
        if (props.fulfillment_order_type == FulfillmentSource.Merchant) {
          const fulfilled_items = items.filter((item, index) => {
            return (
              item.quantity <= 0 ||
              String(item.quantity).trim() == "" ||
              item.request_quantity > item.max_quantity
            );
          });
          if (fulfilled_items.length == 0) {
            return false;
          } else {
            return true;
          }
        } else {
          // 现货履约或物流
          let warehouse_id: any = [],
            stock_out_list: any[] = formData.value.stock_out_list;
          stock_out_list.forEach((item) => {
            if (warehouse_id.indexOf(item.warehouse_id) === -1) {
              warehouse_id.push(item.warehouse_id);
            }
          });
          if (
            warehouse_id.length > 1 &&
            props.submitData.shipment_type == "3"
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    });

    const getFulfillmentOrderItems = computed(() => {
      let items: any = [];
      options.value.cloneTableData.map((item) => {
        if (formData.value.stock_out_ids.indexOf(item.id) > -1) {
          const order =
            props.fulfillment_order_type == FulfillmentSource.Merchant
              ? {
                  id: item.id,
                  quantity: Number(item.request_quantity),
                }
              : {
                  id: item.id,
                  quantity: 0,
                };
          items.push(order);
        }
      });
      return items;
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          if (props.fulfillment_order_id != 0) {
            addFulfillmentOrderShipment();
          } else {
            if (props.id === 0) {
              addShipment();
            } else {
              addShipmentItem();
            }
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const addFulfillmentOrderShipment = async () => {
      const { data } = await ApiFulfillmentOrders.addFulfillmentOrderShipment({
        id: props.fulfillment_order_id,
        items: getFulfillmentOrderItems.value,
        custom_declaration_by: formData.value.custom_declaration_by,
        shipment_type: props.submitData.shipment_type,
        service_provider: props.submitData.service_provider,
        tracking_no: props.submitData.tracking_no,
        destination: props.submitData.destination_warehouse_id,
        pick_up_by: props.submitData.pick_up_by,
      });
      submitButton.value?.removeAttribute("data-kt-indicator");
      loading.value = false;
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          hideModal(addPreStockOutList.value);
          emit("update-list");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const addShipment = async () => {
      const { data } = await ApiShipments.addShipment({
        ...formData.value,
        ...props.submitData,
      });
      loading.value = false;
      submitButton.value?.removeAttribute("data-kt-indicator");
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          resetForm(true);
          getFormInfo();
          emit("update-list");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const addShipmentItem = async () => {
      const { data } = await ApiShipments.addShipmentItem({
        stock_ids: formData.value.stock_out_ids,
        id: props.id,
      });
      loading.value = false;
      submitButton.value?.removeAttribute("data-kt-indicator");
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          resetForm();
          getFormInfo();
          emit("update-list");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const getFulfillmentOrderData = async () => {
      if (props.fulfillment_order_type != FulfillmentSource.Merchant) return;
      const { data } = await ApiFulfillmentOrders.getFulfillmentOrderData({
        id: props.fulfillment_order_id,
      });
      if (data.code == 0) {
        options.value.stock_out_list = data.data.fulfilled_items;
        options.value.cloneTableData = getFilterList();
        options.value.cloneTableData.forEach((item) => {
          item.request_quantity = item.quantity - item.delivery_quantity;
          item.max_quantity = item.quantity - item.delivery_quantity;
        });
        options.value.list = getFilterList();
        let ids: any = [],
          customerMobile: any[] = [],
          customer: any[] = [];
        data.data.fulfilled_items.forEach((item) => {
          ids.push(item.sku_identifier);
          if (customerMobile.indexOf(item.mobile) == -1) {
            customerMobile.push(item.mobile);
            customer.push({
              label: item.customer_name,
              value: item.mobile,
            });
          }
        });
        options.value.customer = customer;
        if (ids.length != 0) {
          getProductsData({
            sku_id: ids,
            merchant_id: 0,
          });
        }
      }
    };

    const getPreStockOutList = async () => {
      if (props.fulfillment_order_type == FulfillmentSource.Merchant) return;
      if (
        filterOptions.value.sale_order_number === "" &&
        filterOptions.value.destination_warehouse_id === "" &&
        filterOptions.value.customer === "" &&
        props.fulfillment_order_number == ""
      )
        return;
      const params =
        props.fulfillment_order_type == FulfillmentSource.PreStock
          ? {
              fulfillment_order_number: props.fulfillment_order_number,
            }
          : {
              sale_order_number: filterOptions.value.sale_order_number || "",
              warehouse_id: filterOptions.value.destination_warehouse_id || "",
              customer_id: filterOptions.value.customer || "",
            };
      const { data } = await ApiStock.getPreStockOutList(params);
      if (data.code == 0) {
        options.value.stock_out_list = data.data;
        options.value.list = getFilterList();
        options.value.cloneTableData = getFilterList();
        let ids: any = [],
          customerMobile: any[] = [],
          customer: any[] = [];
        data.data.forEach((item) => {
          ids.push(item.sku_identifier);
          if (customerMobile.indexOf(item.mobile) == -1) {
            customerMobile.push(item.mobile);
            customer.push({
              label: item.customer_name,
              value: item.mobile,
            });
          }
        });
        options.value.customer = customer;
        if (ids.length != 0) {
          getProductsData({
            sku_id: ids,
            merchant_id: 0,
          });
        }
      }
    };

    const getShowInfo = () => {
      if (props.fulfillment_order_type == FulfillmentSource.Merchant) {
        getFulfillmentOrderData();
      } else {
        getPreStockOutList();
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["fulfillment_delivery_type"],
      });
      if (data.code == 0) {
        options.value.custom_declaration_by =
          data.data.fulfillment_delivery_type.items;
      }
    };

    const getFormInfo = async () => {
      loading.value = true;
      multipleTableRef.value?.clearSelection();
      Promise.all([
        getFulfillmentOrderData(),
        getPreStockOutList(),
        getTaggingData(),
      ])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const resetForm = (flag = false) => {
      formRef.value?.resetFields();
      options.value.list = [];
      options.value.stock_out_list = [];
      options.value.cloneTableData = [];
      formData.value.stock_out_ids = [];
      formData.value.stock_out_list = [];
      if (!flag) {
        emit("reset-form");
        filterOptions.value.customer = "";
        filterOptions.value.priority = "";
        filterOptions.value.sale_order_number = "";
        filterOptions.value.destination_warehouse_id = "";
        filterOptions.value.order_date_start = null;
        filterOptions.value.order_date_end = null;
      }
    };

    const typeChange = async (val) => {
      multipleTableRef.value?.clearSelection();
      options.value.list = await getFilterList();
      // getFormInfo();
    };

    const handleBack = () => {
      hideModal(addPreStockOutList.value);
    };

    const modalShowListener = (modalEl: HTMLElement | null, callback): void => {
      if (!modalEl) {
        return;
      }
      modalEl.addEventListener("show.bs.modal", callback);
    };

    onMounted(() => {
      modalShowListener(addPreStockOutList.value, () => {
        isHide.value = false;
        getFormInfo();
      });
      modalHideListener(addPreStockOutList.value, () => {
        isHide.value = true;
        resetForm();
      });
    });

    return {
      t,
      formatDateTime,
      props,
      addPreStockOutList,
      loading,
      formData,
      multipleTableRef,
      submitButton,
      formRef,
      options,
      rules,
      FulfillmentSource,
      submit,
      resetForm,
      handleSelectionChange,
      handleSelectChange,
      handleFilter,
      handleReset,
      setTableSelect,
      handleBack,
      getFilterList,
      typeChange,
      isDisabledSubmit,
      getProductIdData,
      getOfficialPrices,
      previewImages,
    };
  },
});
