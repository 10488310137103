
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";
import { MBDatatablePlus } from "magicbean-saas-common";
import { useRoute, useRouter } from "vue-router";
import { ApiFulfillmentOrders, ApiShipments } from "@/core/api";
import addShipments from "./AddTmsShipments.vue";
import productTable from "@/components/table/Product.vue";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { CommonButtonType } from "@/core/directive/type/common";
import { ShipmentTable } from "@/core/directive/interface/shipment";
import {
  FulfillmentOrderStatus,
  FulfillmentSource,
} from "@/core/directive/type/fulfillmentOrder";
import {
  getServiceProviderMap,
  getShipmentStatusMap,
} from "@/core/directive/function/shipment";
import { formatDate, setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "fulfillment-order-shipment",
  components: {
    MBDatatablePlus,
    productTable,
    addShipments,
  },
  setup() {
    const { t } = useI18n();

    const currentInstance: any = getCurrentInstance();

    const route = useRoute();
    const router = useRouter();
    const loading = ref(true);
    const tableData = ref<Array<ShipmentTable>>([]);
    const receivedButton = ref<CommonButtonType>(null);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const isDisabledAdd = ref<boolean>(true);

    const filterOptions = ref<Array<FilterOption>>([
      {
        field: "fulfillment_order_number",
        value: route.params.fulfillment_order_number,
        condition: "eq",
      },
    ]);

    const sortOptions = ref<Array<SortOption>>([
      { field: "created_at", direction: "desc" },
    ]);

    const tableHeader = ref([
      {
        name: t("shipments.trackingNo"),
        key: "tracking_no",
        sortable: false,
      },
      {
        name: t("shipments.from"),
        key: "delivery_from",
        sortable: false,
      },
      {
        name: t("shipments.to"),
        key: "destination",
        sortable: false,
      },
      {
        name: t("shipments.departure"),
        key: "actual_time_of_departure",
        sortable: false,
      },
      {
        name: t("shipments.arrival"),
        key: "actual_time_of_arrival",
        sortable: false,
      },
      {
        name: t("shipments.status"),
        key: "status",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    // watch(
    //   () => currentInstance.parent.parent.ctx._.setupState.loading,
    //   (newValue) => {
    //     const data =
    //       currentInstance.parent.parent.ctx._.setupState
    //         .FulfillmentOrderOverviewData;
    //     options.value.fulfillment_order_type =
    //       data.fulfillment_order_identifier.fulfillment_type;
    //     options.value.fulfillment_type = data.fulfillment.delivery_type;
    //     console.log(data.fulfillment_order_identifier.status);
    //     if (
    //       data.fulfillment_order_identifier.status ==
    //       FulfillmentOrderStatus.Approved
    //     ) {
    //       isDisabledAdd.value = false;
    //     }
    //   }
    // );

    onMounted(() => {
      setModuleBCN(t, route, router);
      getShipmentList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
      getFulfillmentData();
      // if (!currentInstance.parent.parent.ctx._.setupState.loading) {
      //   const data =
      //     currentInstance.parent.parent.ctx._.setupState
      //       .FulfillmentOrderOverviewData;
      //   options.value.fulfillment_order_type =
      //     data.fulfillment_order_identifier.fulfillment_type;
      //   options.value.fulfillment_type = data.fulfillment.delivery_type;
      //   if (
      //     data.fulfillment_order_identifier.status ==
      //     FulfillmentOrderStatus.Processing
      //   ) {
      //     isDisabledAdd.value = false;
      //   }
      // }
    });

    const options = ref({
      fulfillment_order_type: FulfillmentSource.Merchant,
      fulfillment_type: "",
    });

    const getShipmentList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiShipments.getShipmentList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getShipmentList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      getShipmentList(
        currentPage.value,
        pagesize,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const renew = () => {
      getShipmentList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getShipmentList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleReceivedShipment = (id) => {
      Swal.fire({
        text: t("common.confirmTheReceiptOfGoods"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (receivedButton.value) {
            receivedButton.value.setAttribute("data-kt-indicator", "on");
          }
          const { data } = await ApiShipments.deliveredShipment({
            id: id,
          });
          receivedButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            Swal.fire({
              text: "Received successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              renew();
            });
          }
        }
      });
    };

    const getServiceProvider = computed(() => {
      return (value) => {
        return getServiceProviderMap(value);
      };
    });

    const getReceiveStatus = (status) => {
      if (status != 40) {
        return true;
      } else {
        return false;
      }
    };

    const getLocation = computed(() => {
      return (location, needName: boolean) => {
        let result = "";
        if (needName) {
          result += location.name;
        } else if (location.city) {
          result += location.city;
        }
        if (location.country_iso_2) {
          if ((location.name && needName) || (location.city && !needName)) {
            result += ", ";
          }
          result += location.country_iso_2;
        }
        return result;
      };
    });

    const getFulfillmentData = async () => {
      isDisabledAdd.value = true;
      const { data } = await ApiFulfillmentOrders.getFulfillmentOrderData({
        id: route.params.id,
      });
      if (data.code == 0) {
        options.value.fulfillment_order_type =
          data.data.fulfillment_order_identifier.fulfillment_type;
        options.value.fulfillment_type = data.data.fulfillment.delivery_type;
        if (
          data.data.fulfillment_order_identifier.status ==
          FulfillmentOrderStatus.Approved
        ) {
          isDisabledAdd.value = false;
        }
      }
    };

    return {
      t,
      formatDate,
      getShipmentStatusMap,
      route,
      loading,
      isDisabledAdd,
      options,
      receivedButton,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      getShipmentList,
      handleReceivedShipment,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      getServiceProvider,
      getLocation,
      getReceiveStatus,
      renew,
    };
  },
});
