
import { defineComponent, ref, onMounted } from "vue";
import { ApiBase, ApiFulfillmentOrders } from "@/core/api";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { TaggingItem } from "@/core/directive/interface/common";

interface Filter {
  sale_order_number: string;
  destination_warehouse_id: string;
  customer: string;
  priority: string;
  order_date_start: string | null;
  order_date_end: string | null;
}

export default defineComponent({
  name: "filter-pre-stock-out-dropdown",
  components: {},
  props: {
    customer: { type: Array, required: true },
    shipmentType: { type: String, required: true },
  },
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<Filter>({
      sale_order_number: "",
      destination_warehouse_id: "",
      customer: "",
      priority: "",
      order_date_start: null,
      order_date_end: null,
    });

    const rules = ref({
      destination_warehouse_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      customer: [] as TaggingItem[],
      customerLoading: false,
      is_priority: [
        {
          label: t("salesOrder.priorityNormal"),
          value: "0",
        },
        {
          label: t("salesOrder.priorityVip"),
          value: "1",
        },
      ],
      warehouse: [],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          emit("submit-filter", formData.value);
        } else {
          // showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("submit-filter", formData.value);
      emit("reset-filter", formData.value);
    };

    const getWarehouseData = async () => {
      const { data } = await ApiFulfillmentOrders.getWarehouseData({});
      if (data.code == 0) {
        options.value.warehouse = data.data;
      }
    };

    const getCustomerOptions = async (query: string) => {
      options.value.customerLoading = true;
      const { data } = await ApiBase.getCustomerSourceData({ name: query });
      options.value.customerLoading = false;
      if (data.code == 0) {
        options.value.customer = data.data;
      }
    };

    const debounceCustomerSearch = _.debounce(getCustomerOptions, 1000);

    const searchCustomerItems = (query: string) => {
      debounceCustomerSearch(query);
    };

    const getFromInfo = () => {
      // loading.value = true;
      Promise.all([getWarehouseData()])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      getFromInfo();
    });

    return {
      t,
      props,
      formData,
      rules,
      formRef,
      submit,
      handleReset,
      searchCustomerItems,
      options,
    };
  },
});
