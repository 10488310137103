
import { ApiBase, ApiShipments } from "@/core/api";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  ProductItem,
  ProductItemOfficialPrices,
} from "@/core/directive/interface/order";
import { NumberOrString } from "@/core/directive/type/common";
import {
  getOrderOfficialPrices,
  getOrderProductIdData,
} from "@/core/directive/function/order";
import ProductInfo from "../layout/ProductInfo.vue";
import SkuInfo from "../layout/SkuInfo.vue";

export default defineComponent({
  name: "kt-product-table",
  props: {
    id: { type: String || Number, required: true },
    sale_order_number: { type: String || Number, required: false },
    fulfillment_order_number: { type: String || Number, required: false },
  },
  components: {
    ProductInfo,
    SkuInfo,
  },
  setup(props) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);

    // watch(
    //   () => [
    //     formData.value.selection_mode,
    //     formData.value.mb_segmentation,
    //     formData.value.dedicated_group_level,
    //     formData.value.coverage_scope,
    //   ],
    //   (
    //     [newMode, newSegmentation, newGroup, newScope],
    //     [oldMode, oldSegmentation, oldGroup, oldScope]
    //   ) => {
    //     if (newMode || newSegmentation || newGroup || newScope) {
    //       // searchItemsMemberList();
    //       // searchItemsSalesInteractionList();
    //     }
    //   }
    // );

    const formData = ref({
      product_items: [],
    });

    const options = ref({
      product_items: [] as ProductItem[],
      loadingProducts: true,
    });

    const getTableData = async () => {
      loading.value = true;
      const { data } = await ApiShipments.getShipmentItems({
        id: props.id,
        sale_order_number: props.sale_order_number,
        fulfillment_order_number: props.fulfillment_order_number,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value.product_items = data.data;
        let ids: any = [];
        data.data.forEach((item) => {
          ids.push(item.sku_identifier);
        });
        getProductsData({
          sku_id: ids,
          merchant_id: 0,
        });
      }
    };

    const getProductsData = async (ids) => {
      options.value.loadingProducts = true;
      const { data } = await ApiBase.getProductsData(ids);
      options.value.loadingProducts = false;
      if (data.code == 0) {
        options.value.product_items = data.data;
      }
    };

    const getProductIdData = computed(() => {
      return (id: NumberOrString) => {
        return getOrderProductIdData(id, options.value.product_items);
      };
    });

    const getOfficialPrices = computed(() => {
      return (officialPrices: ProductItemOfficialPrices[] | undefined) => {
        return getOrderOfficialPrices(officialPrices);
      };
    });

    onMounted(() => {
      getTableData();
    });

    return {
      t,
      loading,
      formData,
      options,
      getProductIdData,
      getOfficialPrices,
    };
  },
});
